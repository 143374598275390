@import "../../base/fn";
@bgColor:#ECECEC;
.weui_actionsheet {
    position: fixed;
    left: 0;
    bottom: 0;
    transform: translate(0, 100%);
    backface-visibility: hidden;
    z-index: 2;
    width: 100%;
    background-color: @pageDefaultBackgroundColor;
    //slide up animation
    transition: transform .3s;
}
.weui_actionsheet_menu{
    background-color: #FFFFFF;
}
.weui_actionsheet_action {
    margin-top: 6px;
    background-color: #FFFFFF;
}
.weui_actionsheet_cell {
    position: relative;
    padding: 10px 0;
    text-align: center;
    font-size: 18px;
    &:before {
        .setTopLine(@weuiCellBorderColor);
    }
    &:active{
        background-color: @bgColor;
    }
    &:first-child{
        &:before{
            display: none;
        }
    }
}

//actionSheet aniamtion
.weui_actionsheet_toggle{
    transform: translate(0, 0);
}