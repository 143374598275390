@import "../../../base/fn";

.weui_cell_select {
    padding: 0;
    .weui_select {
        padding-right: 30px;
    }
    .weui_access_icon {

    }
    .weui_cell_bd{
        &:after{
            content: " ";
            .setArrow_Wap(right, 6px, #C8C8CD, 2px);

            position: absolute;
            top: 50%;
            right: @weuiCellGapH;
            margin-top: -3px;
        }
    }
}

.weui_select {
    -webkit-appearance: none;
    border: 0;
    outline: 0;
    background-color: transparent;
    width: 100%;
    font-size: inherit;
    height: @weuiCellHeight;
    position: relative;
    z-index: 1;
    padding-left: @weuiCellGapH;
}
