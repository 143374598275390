@import "../../../base/fn";

.weui_cells_checkbox {
    .weui_cell_hd {
        padding-right: @weuiCellInnerGapH;
    }
    .weui_cell {
        &:active {
            background-color: #ECECEC;
        }
    }
    .weui_icon_checked {
        &:before {
            content: '\EA01';
            color: #C9C9C9;
            font-size: 23px;
            display: block;
        }
    }
}

// method2 accessbility
.weui_check {
    // checkbox
    .weui_cells_checkbox & {
        &:checked {
            & + .weui_icon_checked {
                &:before {
                    content: '\EA06';
                    color: #09BB07;
                }
            }
        }
    }
}