@import "../../base/fn";

.weui_article {
    padding: 20px 15px;
    font-size: 15px;
    section {
        margin-bottom: 1.5em;
    }
    h1 {
        font-size: 17px;
        font-weight:400;
        margin-bottom: .75em;
    }
    h2 {
        font-size: 16px;
        font-weight:400;
        margin-bottom: .3em;
    }
    h3 {
        font-weight:400;
        font-size: 15px;
    }
}